const ApplicantId = 4;
const ApplicationName = 'icms';

const ApplicationClientId = {
    icms: 'Thirumal'
}

const HOMEVDRID = 'home'
const TRASHVDRID = 'trash'
 
const RegEx = {
    PAN: /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/,
    USERNAME: /^([a-zA-Z0-9])[a-zA-Z0-9 !@#$%^&*()_+{}\[\]:;<>,.?~\\/-]*$/,
    ONLY_NUMBER: /^[0-9]+$/,
    EMAIL: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
    PASSWORD: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])(?=.{8,})/,
    SPECIAL_CHAR: /[^A-Z a-z 0-9]/,
    CIN: /^([LUu]{1})([0-9]{5})([A-Za-z]{2})([0-9]{4})([A-Za-z]{3})([0-9]{6})$/,
    PASSPORT: /^[a-zA-Z]{1}[0-9]{7}$/,
    VOTER_ID: /^[A-Z]{3}[0-9]{7}$/,
    NUMBER_NON_MANDATE: /^[0-9]*$/,
    PIN_CODE: '^[1-9]{1}[0-9]{2}\\s{0,1}[0-9]{3}$',
    // IBBI_REG_NO: /^([0-9a-zA-Z\-_\/]+\s)*[0-9a-zA-Z\-_/]+$/,
    COMPANYNAME: /^[a-zA-Z0-9]+$/,
    NOSPACE: /^\S.*[a-zA-Z0-9]+.*\S$/,
    MAXNUMBER: /^.[16]$/,
    COMBINATION_OF_LETTERS_OR_ONLY_LETTERS: /^(?=.*[a-zA-Z])[a-zA-Z0-9 !@#$%^&*()_+{}\[\]:;<>,.?~\\/-]*$/,
    REFERENCE_NUMBER: /^(?=.*[a-zA-Z0-9])[a-zA-Z0-9 !@#$%^&*()_+{}\[\]:;"<>,.?~\\/\\/-]*$/,
    PASSPORT: /^[A-Za-z][0-9]{7}$/,
    DIN: /^[0-9]{8}$/,
    VOTER_ID: /^[A-Z]{3}[0-9]{7}$/,
    GSTIN: /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Za-z]{1}[0-9A-Za-z]{1}[0-9A-Za-z]{1}$/,
    IFSC: /^[A-Z]{4}[0][A-Z0-9]{6}$/,
    // IBBI_REG_NO: /^[0-9a-zA-Z\-\_\/\s]{15,40}$/
    IBBI_REG_NO:/^[0-9A-Z\-\_\/]{15,50}$/
}
 
const PartyType = {
    INDIVIDUAL: 1,
    INSTITUTION: 2,
    COOPERATIVESOCIETY: 6,
    BANK: 16,
    FINSTITUTION: 17,
    NBFC: 18,
    LLP: 19,
    COMPANY_DUPLICATE: 4,
    COMPANY: 20,
    PARTNERSHIPFIRM: 21,
    PROPBBUSINESS: 22,
    NCLT: 7,
    BANK_BRANCH: 24,
    GOVT_BODY: 13
}
 
const FinancialCreditorTypes = [
    {
        id: PartyType.COMPANY,
        title: 'Company',
        partyLocale: 'Company'
    },
    {
        id: PartyType.INDIVIDUAL,
        title: 'Individual',
        partyLocale: 'First'
    },
    {
        id: PartyType.LLP,
        title: 'Limited Liability Partnership',
        partyLocale: 'LLP'
    },
    {
        id: PartyType.BANK,
        title: 'Bank',
        partyLocale: 'Bank'
    },
    {
        id: PartyType.FINSTITUTION,
        title: 'Financial Institution',
        partyLocale: 'Financial Institution'
    },
    {
        id: PartyType.NBFC,
        title: 'NBFC (Non-Banking Financial Companies)',
        partyLocale: 'Company'
    },
    {
        id: PartyType.PARTNERSHIPFIRM,
        title: 'Partnership Firm',
        partyLocale: 'Partnership Firm'
    },
    {
        id: PartyType.PROPBBUSINESS,
        title: 'Proprietorship Business',
        partyLocale: 'Proprietorship Business'
    },
    {
        id: PartyType.COOPERATIVESOCIETY,
        title: 'Co-Operative Society',
        partyLocale: 'Co-Operative Society'
    },
    {
        id: PartyType.GOVT_BODY,
        title: 'Government Body',
        partyLocale: 'Government Body'
    }
]

const ResolutionApplicationType = [
    {
        id: PartyType.INDIVIDUAL,
        title: 'Individual',
        partyLocale: 'First'
    },
    {
        id: PartyType.LLP,
        title: 'Limited Liability Partnership',
        partyLocale: 'LLP'
    },
    {
        id: PartyType.COMPANY,
        title: 'Company',
        partyLocale: 'Company'
    },
    {
        id: PartyType.PARTNERSHIPFIRM,
        title: 'Partnership Firm',
        partyLocale: 'Partnership Firm'
    },
    {
        id: PartyType.PROPBBUSINESS,
        title: 'Proprietorship Business',
        partyLocale: 'Proprietorship Business'
    }
]

const IdentificationType = {
    OTHER: 1,
    PASSPORT: 2,
    VOTER_ID: 3,
    PAN: 4, 
    DRIVING_LICENCE: 5,
    RATION_CARD: 6,
    DIN: 7,
    IBBI_REG_IP: 8,
    GSTIN: 9,
    IBBI_REG_PLANT_AND_MACHINARY_VALUERS: 10,
    IBBI_REG_LAND_AND_BUILDING_VALUERS: 11,
    IBBI_REG_SECURITIES_AND_FINANCIAL_VALUERS: 12,
    AADHAAR: 13,
    ICMS_ID: 14,
    CIN: 52,
    IBBI_REG_VAL_ENTITY: 53,
    IBBI_REG_VAL_ORG: 54,
    IFSC: 101,
    GOVT_BODY_ICMS_ID: 63,
    PROP_BUSINESS_PAN: 221,
    PROP_BUSINESS_GSTIN: 222,
    PROP_BUSINESS_ICMS_ID: 223,
    IRDAI_REG_NO: 300
}
 
const MeetingType = {
    OTHER: 0,
    COC: 1,
    SCC: 2,
    COC_MEETING_FOR_EVALUATING_RES_PLAN: 21,
    COC_MEETING_FOR_APPROVING_THE_RFRP: 22,
    MEETING_OF_COC_PASSING_RESOLUTION_FOR_LIQUIDATION: 23,
    CREDITORS_MEETING: 24,
    CONSULTATION_COMMITTEE: 51
}

const MeetingParticipantCd = {
    OTHER: 0,
    COC_MEMBER: 1,
    SCC_MEMBER: 2,
    MONITORING_COMMITTEE_MEMBER: 3,
    CONSULTATION_COMMITTEE_MEMBER: 4
}

const MeetingParticitantTypeByMeetingType = {
    [MeetingType.OTHER]: MeetingParticipantCd.OTHER,
    [MeetingType.COC]: MeetingParticipantCd.COC_MEMBER,
    [MeetingType.SCC]: MeetingParticipantCd.SCC_MEMBER,
    [MeetingType.CONSULTATION_COMMITTEE]: MeetingParticipantCd.CONSULTATION_COMMITTEE_MEMBER,
    // [MeetingType.OTHER]: MeetingParticipantCd.OTHER,
}
 
const ResolutionMeetingTypes = [
    { id: MeetingType.COC, title: 'CoC Meeting'},
    // { id: MeetingType.COC_MEETING_FOR_EVALUATING_RES_PLAN, title: 'CoC Meeting for Evaluating Resolution Plan'},
    // { id: MeetingType.COC_MEETING_FOR_APPROVING_THE_RFRP, title: 'CoC Meeting for Approving the RFRP'},
    // { id: MeetingType.MEETING_OF_COC_PASSING_RESOLUTION_FOR_LIQUIDATION, title: 'Meeting of CoC Passing Resolution for Liquidation'},
    // { id: MeetingType.CREDITORS_MEETING, title: 'Creditors Meeting'},
    { id: MeetingType.OTHER, title: 'Other'}
]

const LiquidationMeetingTypes = [
    { id: MeetingType.SCC, title: 'SCC Meeting'},
    { id: MeetingType.OTHER, title: 'Other'}
    // { id: MeetingType.CONSULTATION_COMMITTEE, title: 'Consultation Committee'}
]

const MatterType = {
    DISCUSSION: 1,
    VOTING: 2,
    RAISED: 3,
    OTHER: 4
}
 
const MatterTypes = [
    {value: MatterType.DISCUSSION, label: 'Discussion'},
    {value: MatterType.VOTING, label: 'Voting'},
    {value: MatterType.RAISED, label: 'Raised'},
    {value: MatterType.OTHER, label: 'Other'}
]
 
const IdentificationByPartyType = {
    [PartyType.INDIVIDUAL]: [
        {
            id: IdentificationType.PAN,
            title: 'PAN'
        },
        {
            id: IdentificationType.PASSPORT,
            title: 'Passport'
        },
        {
            id: IdentificationType.DIN,
            title: 'DIN'
        },
        {
            id: IdentificationType.VOTER_ID,
            title: 'Voter Id'
        },
    ],
    [PartyType.COMPANY]: [
        {
            id: IdentificationType.CIN,
            title: 'CIN'
        },
        {
            id: IdentificationType.PAN,
            title: 'PAN'
        },
        {
            id: IdentificationType.GSTIN,
            title: 'GSTIN'
        },
        {
            id: IdentificationType.IRDAI_REG_NO,
            title: 'IRDAI Reg. No.'
        }
    ],
    [PartyType.COMPANY_DUPLICATE]: [
        {
            id: IdentificationType.PAN,
            title: 'PAN'
        },
        {
            id: IdentificationType.CIN,
            title: 'CIN'
        },
        {
            id: IdentificationType.GSTIN,
            title: 'GSTIN'
        },
        {
            id: IdentificationType.IRDAI_REG_NO,
            title: 'IRDAI Reg. No.'
        }
    ],
    [PartyType.INSTITUTION]: [
        {
            id: IdentificationType.PAN,
            title: 'PAN'
        },
        {
            id: IdentificationType.CIN,
            title: 'CIN'
        },
        {
            id: IdentificationType.GSTIN,
            title: 'GSTIN'
        },
    ],
    [PartyType.LLP]: [
        {
            id: IdentificationType.PAN,
            title: 'PAN'
        },
        {
            id: IdentificationType.LLP,
            title: 'LLPIN'
        }
    ],
    [PartyType.BANK]: [
        {
            id: IdentificationType.PAN,
            title: 'PAN'
        },
        {
            id: IdentificationType.CIN,
            title: 'CIN'
        }
    ],
    [PartyType.FINSTITUTION]: [
        {
            id: IdentificationType.PAN,
            title: 'PAN'
        },
        {
            id: IdentificationType.CIN,
            title: 'CIN'
        },
        {
            id: IdentificationType.GSTIN,
            title: 'GSTIN'
        }
    ],
    [PartyType.NBFC]: [
        {
            id: IdentificationType.PAN,
            title: 'PAN'
        },
        {
            id: IdentificationType.CIN,
            title: 'CIN'
        },
        {
            id: IdentificationType.GSTIN,
            title: 'GSTIN'
        }
    ],
    [PartyType.PARTNERSHIPFIRM]: [
        {
            id: IdentificationType.PAN,
            title: 'PAN'
        },
        {
            id: IdentificationType.CIN,
            title: 'CIN'
        }
    ],
    [PartyType.PROPBBUSINESS]: [
        {
            id: IdentificationType.PROP_BUSINESS_PAN,
            title: 'PAN'
        },
        {
            id: IdentificationType.PROP_BUSINESS_GSTIN,
            title: 'GSTIN'
        },
        {
            id: IdentificationType.PROP_BUSINESS_ICMS_ID,
            title: ''
        }
    ],
    [PartyType.COOPERATIVESOCIETY]: [
        {
            id: IdentificationType.PAN,
            title: 'PAN'
        },
        {
            id: IdentificationType.IRDAI_REG_NO,
            title: 'IRDAI Reg. No.'
        }
    ],
    [PartyType.GOVT_BODY] : [
        {
            id: IdentificationType.GOVT_BODY_ICMS_ID,
            title: ''
        },
        {
            id: IdentificationType.IRDAI_REG_NO,
            title: 'IRDAI Reg. No.'
        }
    ]
}
const DirectorIdentificationType = [
    {
        id: IdentificationType.PAN,
        title: 'PAN'
    },
    {
        id: IdentificationType.DIN,
        title: 'DIN'
    }
]
 
const DefaultCountry = {
    COUNTRY_NAME: "India",
    COUNTRY_ID: "101"
}
 
const AuditTrailValues = {
    REPORT_CD: 102,
    APPLICATION_ID: 4
}
 
const CommitteeType = {
    COC: 1,
    SCC: 2,
    OTHER: 0
}
 
const CommitteeTypeNames = [
    {value: CommitteeType.COC, label: 'Committee of Creditors'},
    {value: CommitteeType.SCC, label: 'Stakeholder Committee'},
    {value: CommitteeType.OTHER, label: 'Other'},
]
 
export const EMAIL = "email";
export const MOBILE_NUMBER = "mobile";
 
const Stage = {
    APPLICATION: 1,
    RESOLUTION: 2,
    LIQUIDATION: 3
}
 
const CaseStage = {
    1: 'Application',
    2: 'Resolution',
    3: 'Liquidation'
}
 
const CreditorType = {
    FINANCIAL: 13,
    SUPPLIER: 14, 
    EMPLOYEE: 15, 
    WORKMAN: 16, 
    OTHER: 52,
    STAKEHOLDER: 17
}

const ApplicantCreditorType = {
    FINANCIAL: 13,
    SUPPLIER: 14, 
    EMPLOYEE: 15, 
    WORKMAN: 16, 
    OTHER: 52
}

const CaseRoles = {
    ROLE_CASE_MANAGER: 6,
    ROLE_CASE_WORKER: 55,
    ROLE_CASE_APPLICANT: 50,
    ROLE_CASE_CLAIMANT: 56,
    ROLE_CASE_COC_MEMBER: 66,
    ROLE_CASE_VALUER: 90,
    ROLE_CASE_RA: 68,
    ROLE_CASE_VIEWER: 49 // TODO
}

const CaseRolesValue = {
    caseManager: {
        id: 'caseManager',
        label: 'Case Admin',
        roleNames: ['CASE_MANAGER', 'ROLE_CASE_MANAGER']
    },
    caseWorker: {
        id: 'caseWorker',
        label: 'Case Manager',
        roleNames: ['CASE_WORKER', 'ROLE_CASE_WORKER']
    },
    caseApplicant: {
        id: 'caseApplicant',
        label: 'Applicant',
        roleNames: ['CASE_APPLICANT', 'ROLE_APPLICANT']
    },
    caseClaimant: {
        id: 'caseApplicant',
        label: 'Claimant',
        roleNames: ['CASE_CLAIMANT', 'ROLE_CLAIMANT']
    },
    caseCoCMember: {
        id: 'caseApplicant',
        label: 'CoC Member',
        roleNames: ['CASE_COC_MEMBER', 'ROLE_COC']
    },
    caseValuer: {
        id: 'caseValuer',
        label: 'Valuer',
        roleNames: ['CASE_VALUER', 'ROLE_RV']
    },
    caseRA: {
        id: 'resolutionApplicant	',
        label: 'Resolution Applicant	',
        roleNames: ['CASE_RA', 'ROLE_RA']
    },
    caseAdmin: {
        id: 'caseManager',
        label: 'Case Admin',
        roleNames: ['CASE_ADMIN', 'ROLE_CASE_ADMIN']
    }
}
 
const Roles = {
    enkAdmin: {
        id: 'enkAdmin',
        label: 'Enkindle Admin',
        roleName: 'ROLE_ENK_ADMIN',
        value: 'Enkindle Admin',
        roleValue: 999,
        relationShip: null
    },
    enkSupport: {
        id: 'enkSupport',
        label: 'Enkindle Support',
        roleName: 'ROLE_ENK_SUPPORT',
        value: 'Enkindle Support',
        roleValue: 998,
        relationShip: null
    },
    administrator: {
        id: 'administrator',
        label: 'Role Account Manager',
        roleName: 'ROLE_ADMINISTRATOR',
        value: 'Account Manager',
        roleValue: 46,
        relationShip: 23
    },
    monitor: {
        id: 'monitor',
        label: 'Role Monitor',
        roleName: 'ROLE_MONITOR',
        value: 'Monitor',
        roleValue: 45,
        relationShip: 24
    },
    accountAdmin: {
        id: 'adminAccount',
        label: 'Role Account Admin',
        roleName: 'ROLE_ACCOUNT_ADMIN',
        value: 'Account Admin',
        roleValue: 5,
        relationShip: 1
    }
}
 
const ContactType = {
    EMAIL: 1,
    PHONE_NUMBER: 2
}
 
const RelationshipType = {
    FINANCIAL_CLAIMANT: 25,
    SUPPLIER_CLAIMANT: 26,
    EMPLOYEE_CLAIMANT: 27,
    WORKMAN_CLAIMANT: 28,
    OTHER_STAKEHOLDER_CLAIMANT: 29,
    OTHER_CREDITOR_CLAIMANT: 30,
    DEBTOR_CASE_MANAGER: 35,
    DEBTOR_CASE_WORKER: 36,
    IPA_IP: 37,
    IBBI_IPA: 39,
    RESOLUTION_RA: 56,
    LIQUIDATION_RA : 57,
    JOINT_RA: 58,
    DEBTOR_ROLE_RA: 59,
    DEBTOR_ROLE_CLAIMANT: 66,
    DEBTOR_ROLE_APPLICANT: 67,
    DEBTOR_ROLE_COC: 68,
    DEBTOR_ROLE_RV: 78
}
 
const AddressTypes = {
    primary: {
        label: 'Principal_Address',
        addressCd: 3
    },
    billing: {
        label: 'Billing_Address',
        addressCd: 4
    },
    registerdWithIBBI: {
        label: 'Registered_Address',
        addressCd: 1
    },
    correspondence: {
        label: 'Correspondence_Address',
        addressCd: 5
    }
}
 
const AssetValuationType = {
    PURCHASE: {
        cd: 11,
        title: 'Purchase_Value'
    },
    MARKET: {
        cd: 13,
        title: 'Market_Value'
    },
    BOOK: {
        cd: 12,
        title: 'Book_Value'
    },
    FAIR_VALUE: {
        cd: 21,
        title: 'Fair_Value'
    },
    LIQ_VALUE: {
        cd: 22,
        title: 'Liquidation_Value'
    },
    ESTIMATED_REALIZABLE_VALUE: {
        cd: 31,
        title: 'Estimated_Realizable_Value'
    },
    AVG_FAIR_VALUE: {
        cd: 23,
        title: 'Avg_Fair_Value'
    },
    AVG_LIQ_VALUE: {
        cd: 24,
        title: 'Avg_Liquidation_Value'
    },
    AVG_REALIZABLE_VALUE: {
        cd: 35,
        title: 'Avg_Realizable_Value'
    }
}
 
const RelationshipCd = {
    DIRECTOR: 32,
    PROMOTER: 34,
    KMP: 33,
    FINANCIAL_CREDITOR: 80,
    SUPPLIER: 81,
    EMPLOYEE: 82,
    WORKMAN: 83,
    CORPORATE_APPLICANT: 84,
    JOINT_APPLICANT: 85
}
 
const ApplicationReport = {
    APP_NAME: 'ICMS',
    TEMPLATE: "icmsAuditTrial"
}
 
const ClaimType = {
    RES_FIN_AS_PRINCIPAL_BORROWER: 71,
    RES_FIN_AS_GUARANTOR: 72,
    RES_FIN_COVERED_UNDER_CLAUSE_H_I: 73,
    RES_HOME_BUYER: 74,
    RES_SUPPLIER: 22,
    RES_EMPLOYEE: 23,
    RES_WORKMAN: 24,
    RES_OTHER_CREDITOR: 25,
    RES_GOVT_BODY: 27,
    LIQ_SUPPLIER: 32,
    LIQ_EMPLOYEE: 33,
    LIQ_WORKMAN: 34,
    LIQ_STAKEHOLDER: 36,
    LIQ_GOVT_BODY: 37,
    LIQ_FIN_AS_PRINCIPAL_BORROWER: 91,
    LIQ_FIN_AS_GUARANTOR: 92,
    LIQ_FIN_COVERED_UNDER_CLAUSE_H_I: 93,
    LIQ_HOME_BUYER: 94
}
 
const BankAccountType = {
    SAVINGS_ACCOUNT: {
        codeCd: 1,
        description: 'Savings Account'
    },
    CURRENT_ACCOUNT: {
        codeCd: 2,
        description: 'Current Account'
    }
}
 
const AccountType = [
    { id: 1, title: 'Savings Account' },
    { id: 2, title: 'Current Account' }
]
 
const DocumentType = {
    OTHER: 0,
    IDENTIFICATION: 1,
    CLAIMANT: 2,
    ASSET: 3,
    DEBT_DEFAULT: 4,
    LOAN: 5,
    ALLOTTEE: 6,
    INVOICE: 7,
    GOVT_DUES: 8,
    SALARY: 9,
    WAGE: 10,
    OTHER_CREDITOR_CLAIM: 11,
    OTHER_STAKEHOLDER_CLAIM: 12,
    RESOLUTION_APPLICANT: 13,
    ORDER: 15,
    ORDER_DOCUMENT: 1300,
    CASE_CHARGE: 17,
    RESOLUTION_PLAN: 18,
    MEETING: 19,
    ADJUDICATION: 20
}
 
const Section = {
    SECTION_21_2 : '21(2)'
}
 
const AssetClass = {
    SECURITIES_OR_FINANCIAL_ASSETS: 'Securities or Financial Assets',
    LAND_AND_BUILDING:  'Land and Building',
    PLANT_AND_MACHINERY: 'Plant and Machinery',
}
 
const PubInfoRelationship = {
    IP:{ codeCd: '37' },
    IPA:{ codeCd: '39' },
    RVO: [
        {
            codeCd: 69,
            code: AssetClass.PLANT_AND_MACHINERY,
        },
        {
            codeCd: 70,
            code: AssetClass.LAND_AND_BUILDING
        },
        {
            codeCd: 71,
            code: AssetClass.SECURITIES_OR_FINANCIAL_ASSETS
        }
    ],
    RV: [
        {
            codeCd: 72,
            code: AssetClass.PLANT_AND_MACHINERY,
        },
        {
            codeCd: 74,
            code: AssetClass.LAND_AND_BUILDING,
        },
        {
            codeCd: 76,
            code: AssetClass.SECURITIES_OR_FINANCIAL_ASSETS
        }
    ],
    RVE: [
        {
            codeCd: 73,
            code: AssetClass.PLANT_AND_MACHINERY,
        },
        {
            codeCd: 75,
            code: AssetClass.LAND_AND_BUILDING,
        },
        {
            codeCd: 77,
            code: AssetClass.SECURITIES_OR_FINANCIAL_ASSETS
        }
    ]
}

const ValuerCaseRelationshipCd = {
    RV: {
        [AssetClass.PLANT_AND_MACHINERY]: {
            codeCd: 72
        },
        [AssetClass.LAND_AND_BUILDING]: {
            codeCd: 74
        },
        [AssetClass.SECURITIES_OR_FINANCIAL_ASSETS]: {
            codeCd: 76
        }
    },
    RVE: {
        [AssetClass.PLANT_AND_MACHINERY]: {
            codeCd: 73
        },
        [AssetClass.LAND_AND_BUILDING]: {
            codeCd: 75
        },
        [AssetClass.SECURITIES_OR_FINANCIAL_ASSETS]: {
            codeCd: 77
        }
    }
};

 
const ResolutionCaseCds = [
    {
        codeCd: 41,
        code: 'Pre-Packaged Insolvency Resolution Process'
    },
    {
        codeCd: 42,
        code: 'Corporate Insolvency Resolution'
    },
    {
        codeCd: 43,
        code: 'Fast Track Corporate Insolvency Resolution Process'
    }
]
 
const LiquidationCds = [
    {
        codeCd: 51,
        code: 'Liquidation Process'
    },
    {
        codeCd: 52,
        code: 'Voluntary Liquidation of corporate Process'
    }
]
 
const ServiceProviderCaseStage = {
    resolutionValuer: 2,
    liquidationValuer: 3
}
 
const Forms = {
    APP_FORM_2: 172,
    RES_FORM_A: 101,
    RES_FORM_AA: 103,
    RES_FORM_C: 104,
    RES_FORM_IP_FORM_A: 107,
    RES_FORM_AB: 108,
    RES_FORM_G: 109,
    RES_FORM_B: 110,
    RES_FORM_C_FT: 111,
    RES_FORM_CA: 112,
    RES_FORM_D: 113,
    RES_FORM_E: 114,
    RES_FORM_F: 115,
    RES_FORM_FA: 116,
    RES_FORM_H: 117,
    LIQ_FORM_B: 118,
    LIQ_FORM_C: 119,
    LIQ_FORM_D: 120,
    LIQ_FORM_E: 121,
    LIQ_FORM_F: 122,
    LIQ_FORM_G: 123
}
 
const Reports = {
    descriptionOfAsset: {
        code: 128,
        reportLocale: 'Description of Asset',
        name: 'Description of Asset',
        template: 'descriptionOfAsset'
    },
    assetMemorandum: {
        code: 129,
        reportLocale: 'Asset Memorandum',
        name: 'Asset Memorandum',
        template: 'assetMemorandum'
    },
    customAssetReport: {
        code: 130,
        reportLocale: 'Custom Asset Report',
        name: 'Custom Asset Report',
        template: 'customAssetReport'
    },
    assetUnderCharge: {
        code: 131,
        reportLocale: 'Asset Under Charge',
        name: 'Asset Under Charge',
        template: 'assetUnderCharge'
    },
    assetValuationAtResolution:{ // Valuer full valuation
        code: 138,
        reportLocale: 'Asset Valuation At Resolution',
        name: 'Valuation Report',
        template: 'assetValuationReport'
    },
    assetValuationAtLiquidation:{ // Valuer full valuation
        code: 139,
        reportLocale: 'Asset Valuation At Liquidation',
        name: 'Valuation Report',
        template: 'assetValuationReport'
    },

    averageValuationAtResolution:{ // IP full valuation
        code: 140,
        reportLocale: 'Average Valuation at Resolution',
        name: 'Average Valuation Report',
        template: 'valuationAverageReport'
    },
    averageValuationAtLiquidation:{ // IP full valuation
        code: 141,
        reportLocale: 'Average Valuation at Liquidation',
        name: 'Average Valuation Report',
        template: 'valuationAverageReport'
    },

    valuationAtResolution:{ // IP full valuation
        code: 145,
        reportLocale: 'Valuation At Resolution',
        name: 'Valuation Report',
        template: 'valuationAtResolution'
    },
    valuationAtLiquidation:{ // IP full valuation
        code: 146,
        reportLocale: 'Valuation At Liquidation',
        name: 'Valuation Report',
        template: 'valuationAtLiquidation'
    },
    complianceReport: {
        code: 105,
        reportLocale: 'Compliance',
        name: 'Compliance',
        template: 'compliance'
    },
    deviationReport: {
        code: 106,
        reportLocale: 'Deviation/Non-Compliance',
        name: 'Deviation/Non-Compliance',
        template: 'deviation'
    }

}
 
const ResClaimFormByRelationshipCd = {
    [RelationshipType.FINANCIAL_CLAIMANT]: {
        code: Forms.RES_FORM_C,
        name: 'Form C',
        template: 'formC',
        reportLocale: 'Form C'
    },
    [RelationshipType.FINANCIAL_CLAIMANT+'homeBuyer']: {
        code: Forms.RES_FORM_CA,
        name: 'Form CA',
        template: 'formCA',
        reportLocale: 'Form CA'
    },
    [RelationshipType.SUPPLIER_CLAIMANT]: {
        code: Forms.RES_FORM_B,
        name: 'Form B',
        template: 'formB',
        reportLocale: 'Form B'
    },
    [RelationshipType.EMPLOYEE_CLAIMANT]: {
        code: Forms.RES_FORM_D,
        name: 'Form D',
        template: 'formD',
        reportLocale: 'Form D'
    },
    [RelationshipType.WORKMAN_CLAIMANT]: {
        code: Forms.RES_FORM_D,
        name: 'Form D',
        template: 'formD',
        reportLocale: 'Form D'
    },
    [RelationshipType.OTHER_CREDITOR_CLAIMANT]: {
        code: Forms.RES_FORM_F,
        name: 'Form F',
        template: 'formF',
        reportLocale: 'Form F'
    },
    RES_AUTH_REP: {
        code: Forms.RES_FORM_E,
        name: 'Form E',
        template: 'formE',
        reportLocale: 'Form E'
    }
}

const FTIRPResClaimFormByRelationshipCd = {
    [RelationshipType.FINANCIAL_CLAIMANT]: {
        code: Forms.RES_FORM_C_FT,
        name: 'Form C',
        template: 'formCFT',
        reportLocale: 'Form C'
    },
    [RelationshipType.SUPPLIER_CLAIMANT]: {
        code: Forms.RES_FORM_B,
        name: 'Form B',
        template: 'formB',
        reportLocale: 'Form B'
    },
    [RelationshipType.EMPLOYEE_CLAIMANT]: {
        code: Forms.RES_FORM_D,
        name: 'Form D',
        template: 'formD',
        reportLocale: 'Form D'
    },
    [RelationshipType.WORKMAN_CLAIMANT]: {
        code: Forms.RES_FORM_D,
        name: 'Form D',
        template: 'formD',
        reportLocale: 'Form D'
    },
    [RelationshipType.OTHER_CREDITOR_CLAIMANT]: {
        code: Forms.RES_FORM_F,
        name: 'Form F',
        template: 'formF',
        reportLocale: 'Form F'
    },
    RES_AUTH_REP: {
        code: Forms.RES_FORM_E,
        name: 'Form E',
        template: 'formE',
        reportLocale: 'Form E'
    }
}
 
const LiqClaimFormByRelationshipCd = {
    [RelationshipType.FINANCIAL_CLAIMANT]: {
        code: Forms.LIQ_FORM_D,
        name: 'Form D',
        template: 'liquidationFormD',
        reportLocale: 'Form D'
    },
    [RelationshipType.SUPPLIER_CLAIMANT]: {
        code: Forms.LIQ_FORM_C,
        name: 'Form C',
        template: 'liquidationFormC',
        reportLocale: 'Form C'
    },
    [RelationshipType.EMPLOYEE_CLAIMANT]: {
        code: Forms.LIQ_FORM_E,
        name: 'Form E',
        template: 'liquidationFormE',
        reportLocale: 'Form E'
    },
    [RelationshipType.WORKMAN_CLAIMANT]: {
        code: Forms.LIQ_FORM_E,
        name: 'Form E',
        template: 'liquidationFormE',
        reportLocale: 'Form E'
    },
    [RelationshipType.OTHER_STAKEHOLDER_CLAIMANT]: {
        code: Forms.LIQ_FORM_G,
        name: 'Form G',
        template: 'liquidationFormG',
        reportLocale: 'Form G'
    },
    LIQ_AUTH_REP: {
        code: Forms.LIQ_FORM_F,
        name: 'Form F',
        template: 'liquidationFormF',
        reportLocale: 'Form F'
    }
}

const ValLiqClaimFormByRelationshipCd = {
    [RelationshipType.FINANCIAL_CLAIMANT]: {
        code: Forms.LIQ_FORM_D,
        name: 'Form C',
        template: 'liquidationFormD',
        reportLocale: 'Form C'
    },
    [RelationshipType.SUPPLIER_CLAIMANT]: {
        code: Forms.LIQ_FORM_C,
        name: 'Form B',
        template: 'liquidationFormC',
        reportLocale: 'Form B'
    },
    [RelationshipType.EMPLOYEE_CLAIMANT]: {
        code: Forms.LIQ_FORM_E,
        name: 'Form D',
        template: 'liquidationFormE',
        reportLocale: 'Form D'
    },
    [RelationshipType.WORKMAN_CLAIMANT]: {
        code: Forms.LIQ_FORM_E,
        name: 'Form D',
        template: 'liquidationFormE',
        reportLocale: 'Form D'
    },
    [RelationshipType.OTHER_STAKEHOLDER_CLAIMANT]: {
        code: Forms.LIQ_FORM_G,
        name: 'Form F',
        template: 'liquidationFormG',
        reportLocale: 'Form G'
    },
    LIQ_AUTH_REP: {
        code: Forms.LIQ_FORM_F,
        name: 'Form E',
        template: 'liquidationFormF',
        reportLocale: 'Form F'
    }
}
 
const UserRoleId = {
    caseManager: {
        roleId: CaseRoles.ROLE_CASE_MANAGER,
        relationshipId: 35,
        value: 'Case_Admin'
    },
    caseWorker: {
        roleId: CaseRoles.ROLE_CASE_WORKER,
        relationshipId: 36,
        value: 'Case_Manager'
    },
    applicant: {
        roleId: CaseRoles.ROLE_CASE_APPLICANT,
        relationshipId: 67,
        value: 'Applicant'
    },
    claimant: {
        roleId: CaseRoles.ROLE_CASE_CLAIMANT,
        relationshipId: 66, // array of id's [31, 49, 50, 51, 52, 53]
        value: 'Claimant'
    },
    coc: {
        roleId: CaseRoles.ROLE_CASE_COC_MEMBER,
        relationshipId: 68,
        value: 'CoC_Member'
    },
    resolutionApplicant: {
        roleId: CaseRoles.ROLE_CASE_RA,
        relationshipId: 59,
        value: 'Resolution_Applicant'
    },
    valuer: {
        roleId: CaseRoles.ROLE_CASE_VALUER,
        relationshipId: 78,
        value: 'Valuer'
    }
}
 
const CaseManagementSideNav = {
    active: { key: 'active', title: 'Active_Cases', pageHeader: 'Active_Cases', navId: 1 },
    inActive: { key: 'inActive', title: 'InActive_Cases', pageHeader: 'InActive_Cases', navId: 2 },
    archived: { key: 'archived', title: 'Archived_Cases', pageHeader: 'Archived_Cases', navId: 3 },
    abandon: { key: 'abandon', title: 'Abandon', pageHeader: 'Abandon', navId: 1 },
    all: { key: 'all', title: 'All_Cases', pageHeader: 'All_Cases', navId: 1 },
    caseManager: { key: 'caseManager', title: 'Case_Manager', pageHeader: 'Case_Manager', relationshipId: UserRoleId.caseManager.relationshipId, navId: 1 },
    caseWorker: { key: 'caseWorker', title: 'Case_Worker', pageHeader: 'Case_Worker', relationshipId: UserRoleId.caseWorker.relationshipId, navId: 1 },
    applicant: { key: 'applicant', title: 'Applicant', pageHeader: 'Applicant', relationshipId: UserRoleId.applicant.relationshipId, navId: 1 },
    claimant: { key: 'claimant', title: 'Claimant', pageHeader: 'Claimant', relationshipId: UserRoleId.claimant.relationshipId, navId: 1 },
    resolutionApplicant: { key: 'resolutionApplicant', title: 'Resolution_Applicant', pageHeader: 'Resolution_Applicant', relationshipId: UserRoleId.resolutionApplicant.relationshipId, navId: 1 },
    valuer: { key: 'valuer', title: 'Valuer', pageHeader: 'Valuer', relationshipId: UserRoleId.valuer.relationshipId, navId: 1 },
}
 
const ApplicationUsers = {
    IP: 'ip',
    CLAIMANT: 'claimant',
    VALUER: 'valuer',
    RESOLUTION_APPLICANT: 'resolutionapplicant',
    OTHER: 'other'
}
 
 
const CaseType = {
    CIRP: 42,
    FTIRP: 43,
    LIQUIDATION: 51,
    VAL_LIQUIDATION: 52
}

const SubRolePermissions = {
    'cd-case-profile': {
        enkSupport: { view: true, edit: true }, 
        1: { view: false, edit: false },
        23: { view: true, edit: false }, 
        24: { view: true, edit: false }
    },
    'cd-timeline': {
        enkSupport: { view: true, edit: true },
        1: { view: false, edit: false },
        23: { view: true, edit: false }, 
        24: { view: true, edit: false }
    },
    'cd-ibbi-request': {
        enkSupport: { view: true, edit: true },
        1: { view: false, edit: false },
        23: { view: false, edit: false }, 
        24: { view: false, edit: false }
    },
    'cd-application-order-appeal': {
        enkSupport: { view: true, edit: true },
        1: { view: false, edit: false },
        23: { view: true, edit: false }, 
        24: { view: true, edit: false }
    },
    'cd-applicant': {
        enkSupport: { view: true, edit: true },
        1: { view: false, edit: false },
        23: { view: true, edit: false }, 
        24: { view: true, edit: false }
    },
    'cd-service-provider': {
        enkSupport: { view: true, edit: true },
        1: { view: false, edit: false },
        23: { view: true, edit: false },
        24: { view: true, edit: false }
    },
    'cd-user-management': {
        enkSupport: { view: true, edit: true },
        1: { view: false, edit: false },
        23: { view: true, edit: false },
        24: { view: true, edit: false }
    },
    'claim-fc': {
        enkSupport: { view: true, edit: true },
        1: { view: false, edit: false },
        23: { view: true, edit: false },
        24: { view: true, edit: false }
    },
    'claim-supplier': {
        enkSupport: { view: true, edit: true },
        1: { view: false, edit: false },
        23: { view: true, edit: false },
        24: { view: true, edit: false }
    },
    'claim-employee': {
        enkSupport: { view: true, edit: true },
        1: { view: false, edit: false },
        23: { view: true, edit: false },
        24: { view: true, edit: false }
    },
    'claim-workman': {
        enkSupport: { view: true, edit: true },
        1: { view: false, edit: false },
        23: { view: true, edit: false },
        24: { view: true, edit: false }
    },
    'claim-othercreditor': {
        enkSupport: { view: true, edit: true },
        1: { view: false, edit: false },
        23: { view: true, edit: false },
        24: { view: true, edit: false }
    },
    'claim-stakeholder': {
        enkSupport: { view: true, edit: true },
        1: { view: false, edit: false },
        23: { view: true, edit: false },
        24: { view: true, edit: false }
    },
    'asset-debtorAssets': {
        enkSupport: { view: true, edit: true },
        1: { view: false, edit: false },
        23: { view: true, edit: false },
        24: { view: true, edit: false }
    },
    'asset-guarantorAssets': {
        enkSupport: { view: true, edit: true },
        1: { view: false, edit: false },
        23: { view: true, edit: false },
        24: { view: true, edit: false }
    },
    'asset-valuationAtResolution': {
        enkSupport: { view: true, edit: true },
        1: { view: false, edit: false },
        23: { view: true, edit: false },
        24: { view: true, edit: false }
    },
    'asset-valuationAtLiquidation': {
        enkSupport: { view: true, edit: true },
        1: { view: false, edit: false },
        23: { view: true, edit: false },
        24: { view: true, edit: false }
    },
    'audit-trail': {
        enkSupport: { view: true, edit: true },
        1: { view: false, edit: false },
        23: { view: true, edit: false },
        24: { view: false, edit: false }
    },
    'audit-communication': {
        enkSupport: { view: true, edit: true },
        1: { view: false, edit: false },
        23: { view: true, edit: false },
        24: { view: false, edit: false }
    },
    'committee': {
        enkSupport: { view: true, edit: true },
        1: { view: false, edit: false },
        23: { view: true, edit: false },
        24: { view: true, edit: false }
    },
    'committee-meeting': {
        enkSupport: { view: true, edit: true },
        1: { view: false, edit: false },
        23: { view: true, edit: false },
        24: { view: true, edit: false }
    },
    'committee-evoting': {
        enkSupport: { view: true, edit: true },
        1: { view: false, edit: false },
        23: { view: true, edit: false },
        24: { view: true, edit: false }
    },
    'committee-classofcreditor': {
        enkSupport: { view: true, edit: true }, 
        23: { view: true, edit: false },
        24: { view: true, edit: false }
    },
    'committee-contribution': {
        enkSupport: { view: true, edit: true },
        1: { view: false, edit: false },
        23: { view: true, edit: false },
        24: { view: true, edit: false }
    },
    'finance-costsheet': {
        enkSupport: { view: true, edit: true },
        1: { view: false, edit: false },
        23: { view: true, edit: false },
        24: { view: true, edit: false }
    },
    'finance-cashflow': {
        enkSupport: { view: true, edit: true },
        1: { view: false, edit: false },
        23: { view: true, edit: false },
        24: { view: true, edit: false }
    },
    'finance-professional': {
        enkSupport: { view: true, edit: true },
        1: { view: false, edit: false },
        23: { view: true, edit: true }, 
        24: { view: true, edit: true }
    },
    'fr-forms': {
        enkSupport: { view: true, edit: true },
        1: { view: false, edit: false },
        administrator: { view: false, edit: false },
        24: { view: false, edit: false }
    },
    'fr-reports': {
        enkSupport: { view: true, edit: true },
        1: { view: false, edit: false },
        23: { view: false, edit: false },
        24: { view: false, edit: false }
    },
    'fr-bulkupload': {
        enkSupport: { view: true, edit: true },
        1: { view: false, edit: false },
        23: { view: false, edit: false },
        24: { view: false, edit: false }
    },
    'res-plan-details': {
        enkSupport: { view: true, edit: true },
        1: { view: false, edit: false },
        23: { view: true, edit: false },
        24: { view: true, edit: false }
    },
    'res-plan-compliance': {
        enkSupport: { view: true, edit: true },
        1: { view: false, edit: false },
        23: { view: true, edit: false },
        24: { view: true, edit: false }
    },
    'res-plan-approval': {
        enkSupport: { view: true, edit: true },
        1: { view: false, edit: false },
        23: { view: true, edit: false },
        24: { view: true, edit: false }
    },
    'res-plan-non-compliance': {
        enkSupport: { view: true, edit: true },
        1: { view: false, edit: false },
        23: { view: true, edit: false },
        24: { view: true, edit: false }
    },
    'vdr-systemfiles': {
        enkSupport: { view: true, edit: true },
        1: { view: false, edit: false },
        23: { view: false, edit: false },
        24: { view: false, edit: false }
    },
    'vdr-generalfiles': {
        enkSupport: { view: true, edit: true },
        1: { view: false, edit: false },
        23: { view: false, edit: false },
        24: { view: false, edit: false }
    },
    'debtor-guarantor': {
        enkSupport: { view: true, edit: true },
        1: { view: false, edit: false },
        23: { view: true, edit: false },
        24: { view: true, edit: false }
    },
    'debtor-division': {
        enkSupport: { view: true, edit: true },
        1: { view: false, edit: false },
        23: { view: true, edit: false },
        24: { view: true, edit: false }
    },
    'debtor-details': {
        enkSupport: { view: true, edit: true },
        1: { view: false, edit: false },
        23: { view: true, edit: false },
        24: { view: true, edit: false }
    },
    'debtor-key-persons': {
        enkSupport: { view: true, edit: true },
        1: { view: false, edit: false },
        23: { view: true, edit: false },
        24: { view: true, edit: false }
    },
    'debtor-adjudication': {
        enkSupport: { view: true, edit: true },
        1: { view: false, edit: false },
        23: { view: true, edit: false },
        24: { view: true, edit: false }
    },
    'debtor-charges': {
        enkSupport: { view: true, edit: true },
        1: { view: false, edit: false },
        23: { view: true, edit: false },
        24: { view: true, edit: false }
    },
    'liq-assetsale': {
        enkSupport: { view: true, edit: true },
        1: { view: false, edit: false },
        23: { view: true, edit: false },
        24: { view: true, edit: false }
    },
}

const AccountTypes = [
    {
        id: BankAccountType.SAVINGS_ACCOUNT.codeCd,
        title: BankAccountType.SAVINGS_ACCOUNT.description
    },
    {
        id: BankAccountType.CURRENT_ACCOUNT.codeCd,
        title: BankAccountType.CURRENT_ACCOUNT.description
    }
]

const ResolutionApplicantCategoryCd = {
    PROSPECTIVE: 1,
    PROVISIONAL: 2,
    FINAL: 3,
    APPROVED: 4
}

const ResolutionApplicantTypes = [
    {
        id: ResolutionApplicantCategoryCd.PROSPECTIVE,
        title: 'Prospective'
    },
    {
        id: ResolutionApplicantCategoryCd.PROVISIONAL,
        title: 'Provisional'
    },
    {
        id: ResolutionApplicantCategoryCd.FINAL,
        title: 'Final'
    },
    {
        id: ResolutionApplicantCategoryCd.APPROVED,
        title: 'Approved'
    },
]

const FormReportPagePermission = {
    CLAIM_FORM: 'claim-form',
    CLAIM_SUMMARY_REPORT: 'claim-summary-report',
    CLAIM_BULKUPLOAD: 'claim-bulkupload',
    ASSET_REPORT: 'asset-report',
    ASSET_BULKUPLOAD: 'asset-bulkupload',
    CHARGE_REPORT: 'charge-report'
}
 
const ResolutionCommitteeMemberTypes = [
    // {
    //     relationshipCd: '',
    //     relationshipName: 'All',
    // },
    {
        relationshipCd: RelationshipType.FINANCIAL_CLAIMANT,
        relationshipName: 'Financial Creditor',
    },
    {
        relationshipCd: RelationshipType.SUPPLIER_CLAIMANT,
        relationshipName: 'Operation Creditor - Supplier',
    },
    {
        relationshipCd: RelationshipType.EMPLOYEE_CLAIMANT,
        relationshipName: 'Operation Creditor - Employee',
    },
    {
        relationshipCd: RelationshipType.WORKMAN_CLAIMANT,
        relationshipName: 'Operation Creditor - Workman',
    },
    {
        relationshipCd: RelationshipType.OTHER_CREDITOR_CLAIMANT,
        relationshipName: 'Other Creditor',
    }
]

const LiquidationCommitteeMemberTypes = [
    // {
    //     relationshipCd: '',
    //     relationshipName: 'All',
    // },
    {
        relationshipCd: RelationshipType.FINANCIAL_CLAIMANT,
        relationshipName: 'Financial Creditor',
    },
    {
        relationshipCd: RelationshipType.SUPPLIER_CLAIMANT,
        relationshipName: 'Operation Creditor - Supplier',
    },
    {
        relationshipCd: RelationshipType.EMPLOYEE_CLAIMANT,
        relationshipName: 'Operation Creditor - Employee',
    },
    {
        relationshipCd: RelationshipType.WORKMAN_CLAIMANT,
        relationshipName: 'Operation Creditor - Workman',
    },
    {
        relationshipCd: RelationshipType.OTHER_STAKEHOLDER_CLAIMANT,
        relationshipName: 'Stakeholder',
    }
]

const ResolutionVDRRoleTypes = [
    {
        relationshipCd: RelationshipType.FINANCIAL_CLAIMANT,
        relationshipName: 'Financial Creditor',
    },
    {
        relationshipCd: RelationshipType.SUPPLIER_CLAIMANT,
        relationshipName: 'Operation Creditor - Supplier',
    },
    {
        relationshipCd: RelationshipType.EMPLOYEE_CLAIMANT,
        relationshipName: 'Operation Creditor - Employee',
    },
    {
        relationshipCd: RelationshipType.WORKMAN_CLAIMANT,
        relationshipName: 'Operation Creditor - Workman',
    },
    {
        relationshipCd: RelationshipType.OTHER_CREDITOR_CLAIMANT,
        relationshipName: 'Other Creditor',
    },
    {
        relationshipCd: RelationshipType.RESOLUTION_RA,
        relationshipName: 'Resolution Applicant',
    }
]

const LiquidationVDRRoleTypes = [
    {
        relationshipCd: RelationshipType.FINANCIAL_CLAIMANT,
        relationshipName: 'Financial Creditor',
    },
    {
        relationshipCd: RelationshipType.SUPPLIER_CLAIMANT,
        relationshipName: 'Operation Creditor - Supplier',
    },
    {
        relationshipCd: RelationshipType.EMPLOYEE_CLAIMANT,
        relationshipName: 'Operation Creditor - Employee',
    },
    {
        relationshipCd: RelationshipType.WORKMAN_CLAIMANT,
        relationshipName: 'Operation Creditor - Workman',
    },
    {
        relationshipCd: RelationshipType.OTHER_STAKEHOLDER_CLAIMANT,
        relationshipName: 'Stakeholder',
    },
    {
        relationshipCd: RelationshipType.LIQUIDATION_RA,
        relationshipName: 'Resolution Applicant',
    }
]
 
const ClaimStatusType = {
    RES_CLAIM_SUBMISSION_PENDING: 31,
    RES_CLAIM_SUBMITTED: 32,
    RES_CLAIM_RECTIFICATION_REUQIRED: 33,
    RES_CLAIM_RE_SUBMITTED: 34,
    RES_CLAIM_VERIFIED: 35,
    RES_CLAIM_REJECTED: 36,
    RES_CLAIM_VERIFICATION_IN_PROGRESS: 37,
    LIQ_CLAIM_SUBMISSION_PENDING: 71,
    LIQ_CLAIM_SUBMITTED: 72,
    LIQ_CLAIM_RECTIFICATION_REUQIRED: 73,
    LIQ_CLAIM_RE_SUBMITTED: 74,
    LIQ_CLAIM_VERIFIED: 75,
    LIQ_CLAIM_REJECTED: 76,
    LIQ_CLAIM_VERIFICATION_IN_PROGRESS: 77,
}
 
const ResolutionClaimStatus = [
    ClaimStatusType.RES_CLAIM_SUBMISSION_PENDING,
    ClaimStatusType.RES_CLAIM_SUBMITTED,
    ClaimStatusType.RES_CLAIM_RECTIFICATION_REUQIRED,
    ClaimStatusType.RES_CLAIM_RE_SUBMITTED,
    ClaimStatusType.RES_CLAIM_VERIFICATION_IN_PROGRESS,
    ClaimStatusType.RES_CLAIM_VERIFIED,
    ClaimStatusType.RES_CLAIM_REJECTED,
]
 
const LiquidationClaimStatus = [
    ClaimStatusType.LIQ_CLAIM_SUBMISSION_PENDING,
    ClaimStatusType.LIQ_CLAIM_SUBMITTED,
    ClaimStatusType.LIQ_CLAIM_RECTIFICATION_REUQIRED,
    ClaimStatusType.LIQ_CLAIM_RE_SUBMITTED,
    ClaimStatusType.LIQ_CLAIM_VERIFICATION_IN_PROGRESS,
    ClaimStatusType.LIQ_CLAIM_VERIFIED,
    ClaimStatusType.LIQ_CLAIM_REJECTED,
]

const PartyContactTypes = {
    OTHER: 0,
    REGISTERED_CONTACT: 1,
    LOGIN_ID: 2,
    PRIMARY_CONTACT: 3,
    BILLING_CONTACT: 4,
    CORRESPONDENCE_CONTACT: 5
}

const PartyContactTypesLocale = {
    [PartyContactTypes.OTHER]: 'Not Specified',
    [PartyContactTypes.REGISTERED_CONTACT]: 'Registered Contact',
    [PartyContactTypes.LOGIN_ID]: 'Login Id',
    [PartyContactTypes.PRIMARY_CONTACT]: 'Primary/Principal Contact',
    [PartyContactTypes.BILLING_CONTACT]: 'Billing Contact',
    [PartyContactTypes.CORRESPONDENCE_CONTACT]: 'Correspondence Contact'
}

const CaseStatus = {
    active:{
        statusCd:1
    },
    inActive:{
        statusCd:2
    },
    archived:{
        statusCd:3
    },
    1:{
        status: 'Active'
    },
    2:{
        status: 'InActive'
    },
    3:{
        status: 'Archived'
    }
    
}

const EndDateInfinite = "+999999999-12-31T23:59:59.999999999-18:00";

const ContributionRelationshipCd = {
    FINANCIAL_CREDITOR_AUTHORIZED_REPRESENTATIVE: 25,
    SUPPLIER_AUTHORIZED_REPRESENTATIVE: 26,
    EMPLOYEE_AUTHORIZED_REPRESENTATIVE: 27,
    WORKMAN_AUTHORIZED_REPRESENTATIVE: 28,
    SHAREHOLDER_AUTHORIZED_REPRESENTATIVE:29,
    OTHER_CREDITOR_AUTHORIZED_REPRESENTATIVE:30
}


const TmelineProcessCd = {
    APPLICATION_PROCESS: 11,
    RESOLUTION_PROCESS: 12,
    LIQUIDATION_PROCESS: 13
}

const SupportTeamContactDetails = {
    email: 'ipsupport@nesl.co.in, icms@enkindletech.com',
    phone:'1800 599 2345, 9632079220, 8904179220',
    website: 'https://nesl.co.in/'
}

const DocumentExtension = {
    image: {'image/jpeg': ['.jpg', '.jpeg', '.png', '.gif']},
    video: {'video/*': ['.flv', '.mkv', '.mp4', '.avi', '.mts']},
    pdf: {'application/pdf': ['.pdf', '.PDF']},
    otherDocument:  {'application/pdf': [ '.pdf', '.doc', '.docx', '.xls', '.xlsx'], 'image/jpeg': ['.jpg', '.jpeg', '.png', '.gif']},
    pdf_image: {'application/pdf': ['.pdf', '.PDF'], 'image/jpeg': ['.jpg', '.jpeg', '.png', '.gif']},
    pdfExcel: {'application/pdf': [ '.pdf', '.xls', '.xlsx']},
    excel: {'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet ': ['.xls', '.xlsx']}
}

const UploadDocExt = {
    default: DocumentExtension.pdf,           // Default
    pdfExcel: DocumentExtension.pdfExcel,      // Pdf and Excel
    excel: DocumentExtension.excel,
    301: DocumentExtension.image,                       // Image
    302: DocumentExtension.video,                       // Video
    303: DocumentExtension.pdf,                         // Possession Certificate
    304: DocumentExtension.otherDocument,               // Other document
    1400: DocumentExtension.pdf_image,                  // Copy of the Security Deed
    1401: DocumentExtension.pdf_image,                  // Copy of the Valuation report
    1402: DocumentExtension.pdf_image,                  // Proof of Registration with CERSAI
    1403: DocumentExtension.pdf_image,                  // Copy of the Certificate of Registration of Charge
    1404: DocumentExtension.pdf_image,                  // Any other document relating to creation of security  
    1405: DocumentExtension.pdf_image                   // Secured Assets Sale Document
}

const ReportMimeType = {
    PDF: 'PDF_A',
    EXCEL: 'EXCEL'
}

const ReportMineTypeLocales =[
    {id: ReportMimeType.PDF, title: 'PDF'},
    {id: ReportMimeType.EXCEL, title: 'EXCEL'}
]

const CreateIdentifications = [
    {
        id: IdentificationType.PAN,
        title: 'PAN'
    },
    {
        id: IdentificationType.IBBI_REG_IP,
        title: 'IBBI - IP- REGISTRATION NUMBER'
    },
    {
        id: IdentificationType.GSTIN,
        title: 'GSTIN'
    },
]

const BulkUploadFileName  = {
    FINANCIAL_CREDITOR_ALLOTTEE: 'Allottee Bulk Upload.xlsx',
    ASSET: 'Asset Bulk Upload.xlsx',

}

export {
    ApplicantId, ApplicationClientId, IdentificationType, RegEx, DefaultCountry, IdentificationByPartyType, FinancialCreditorTypes, PartyType,
    Roles, ContactType, AddressTypes, AssetValuationType, ApplicationReport,AuditTrailValues, RelationshipCd, DirectorIdentificationType,
    Stage, CreditorType, RelationshipType, ClaimType, BankAccountType, AccountType, DocumentType, Section, PubInfoRelationship, AssetClass,
    ResolutionCaseCds, LiquidationCds, Forms, ServiceProviderCaseStage, ResClaimFormByRelationshipCd,
    LiqClaimFormByRelationshipCd, CaseRoles, CaseManagementSideNav, UserRoleId, ApplicationUsers, CommitteeType,
    CommitteeTypeNames, CaseType, MeetingType, ResolutionMeetingTypes, MatterType, MatterTypes,
    FormReportPagePermission, CaseStage, SubRolePermissions, ResolutionApplicationType, AccountTypes, 
    ResolutionApplicantTypes, Reports, CaseStatus, ClaimStatusType, ResolutionClaimStatus, 
    LiquidationClaimStatus, CaseRolesValue, LiquidationMeetingTypes, LiquidationCommitteeMemberTypes,
    ResolutionCommitteeMemberTypes, ResolutionApplicantCategoryCd, EndDateInfinite, TmelineProcessCd, UploadDocExt,
    ContributionRelationshipCd, PartyContactTypes, PartyContactTypesLocale, ApplicationName,
    SupportTeamContactDetails, MeetingParticitantTypeByMeetingType, ReportMimeType, ReportMineTypeLocales, CreateIdentifications,
    ValuerCaseRelationshipCd, ValLiqClaimFormByRelationshipCd, BulkUploadFileName, FTIRPResClaimFormByRelationshipCd,
    ResolutionVDRRoleTypes, LiquidationVDRRoleTypes, HOMEVDRID, TRASHVDRID
};
